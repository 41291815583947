// _mixins.scss
@use 'config' as *;

// // Application-wide Sass mixins

// // Responsive breakpoints manager
// // @author Kitty Giraudel
// // @param {String} $inputBreak - Breakpoint name
// // @require _config => $breakpoints
@mixin respond-to($inputBreak) {
  // // If the key exists in the map
  @if map-has-key($breakpoints, $inputBreak) {
    // // Prints a media query based on the value
    @media (min-width: map-get($breakpoints, $inputBreak)) {
      // @debug "Oi then!: `#{$inputBreak}`";
      --breaknote: `#{$inputBreak}`;
      // $test: $inputBreak;
      @content;
    }
  }
  // // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$inputBreak}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}
