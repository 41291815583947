@use '../../assets/styles/main' as *;

.button-text {
  position: relative;
  width: 93px;
  height: 26px;
  background-color: var(--clr-accent);
  border: 0;
  border-radius: 16px;
  outline: none;
  cursor: pointer;
  overflow: hidden;
  // // // From 960px width
  // @include respond-to('custom') {}

  // // From 960px width
  @include respond-to('little') {
    width: 112px;
    height: 32px;
  }

  // // From 1280px width
  @include respond-to('medium') {
    width: m(112);
    height: m(32);
    // margin-top: m(-16);
    border-radius: m(16);
  }

  // // From 1920px width
  @include respond-to('prominent') {
    width: 168px;
    height: 47.61px;
    // margin-top: -24px;
    border-radius: 24px;
  }

  &:hover {
    opacity: 0.9;
  }

  &__copy {
    font-family: var(--ft-ui7);
    // font-size: 12px;
    font-size: 14px;
    line-height: 10px;
    color: var(--clr-primary-lght);
    // // // From 960px width
    // @include respond-to('custom') {
    //   // line-height: 10px;
    // }

    // // From 960px width
    @include respond-to('little') {
      font-size: 14px;
      line-height: 10px;
    }

    // // From 1280px width
    @include respond-to('medium') {
      font-size: m(14);
      line-height: m(10);
    }

    // // From 1920px width
    @include respond-to('prominent') {
      font-size: 21px;
      line-height: 15px;
    }
  }
}
