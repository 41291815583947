@use '../../assets/styles/main' as *;

.viewer {
  width: 100%;
  height: 100%;
  color: var(--clr-primary-lght);
  background-color: var(--clr-secondary);
  &__container {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
  }

  &__aside {
    display: none;
    width: 100%;
    height: 100%;
    // // From 720px width
    @include respond-to('custom') {
      display: unset;
    }
  }

  &__center {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100vw;
    height: 100vh;
    height: 87vh;
    margin: 0 auto;
    // // From 720px width
    @include respond-to('custom') {
      width: unset;
      width: fit-content;
      height: 100vh;
    }
  }

  &__video {
    width: 100vw;
    height: 87vh;
    // // From 720px width
    @include respond-to('custom') {
      width: unset;
      height: 100%;
    }
  }

  &__play-icon {
    position: absolute;
    width: 76px;
    height: 76px;
    // // From 1280px width
    @include respond-to('medium') {
      width: m(76);
      height: m(76);
    }

    // // From 1920px width
    @include respond-to('prominent') {
      width: 114px;
      height: 114px;
    }
  }

  .flyer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: var(--clr-primary-lght);
    &__container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: c(168);
      max-width: 985px;
      // // From 1280px width
      @include respond-to('medium') {
        width: 78%;
      }
    }

    &__header,
    &__publisher,
    &__action {
      display: flex;
      align-items: center;
    }

    &__header,
    &__action {
      justify-content: space-between;
    }

    &__header {
      width: 100%;
      // height: c(36);
      height: 36px;
      // margin-bottom: c(15);
      margin-bottom: 15px;
      z-index: 1;
      // // From 1280px width
      @include respond-to('medium') {
        height: m(36);
        margin-bottom: m(15);
      }

      // // From 1920px width
      @include respond-to('prominent') {
        height: 54px;
        margin-bottom: 22.5px;
      }
    }

    &__publisher,
    &__action, {
      height: 100%;
      z-index: 0;
    }

    &__publisher {
      height: 100%;
      overflow: hidden;
      // // From 1280px width
      @include respond-to('medium') {
        max-width: calc(100% - m(87));
      }

      // // From 1920px width
      @include respond-to('prominent') {
        max-width: calc(100% - 131px);
      }
    }

    &__publisher-icon,
    &__action-bubble {
      height: 100%;
      border-radius: 50%;
    }

    &__publisher-name {
      // margin-left: c(9);
      margin-left: 9px;
      font-family: var(--ft-ui5);
      // // font-size: c(14);
      // font-size: 14px;
      font-size: 18px;
      // // line-height: c(17);
      // line-height: 17px;
      line-height: 20px;
      // // From 1280px width
      @include respond-to('medium') {
        margin-left: m(9);
        font-size: m(18);
        line-height: m(20);
      }

      // // From 1920px width
      @include respond-to('prominent') {
        margin-left: 13.5px;
        font-size: 27px;
        line-height: 30px;
      }
    }

    &__action {
      position: absolute;
      // width: c(87);
      width: 87px;
      // height: c(36);
      height: 36px;
      // margin: c(20) c(20) 0 0;
      margin: 20px 20px 0 0;
      top: 0;
      right: 0;
      // // From 1280px width
      @include respond-to('medium') {
        position: relative;
        width: m(87);
        height: m(36);
        margin: unset;
        top: unset;
        right: unset;
      }

      // // From 1920px width
      @include respond-to('prominent') {
        width: 131px;
        height: 54px;
      }
    }

    &__action-bubble {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      // width: c(36);
      width: 36px;
      background-color: rgba(72, 72, 72, 0.5);
      overflow: hidden;

      // // From 1280px width
      @include respond-to('medium') {
        width: m(36);
      }

      // // From 1920px width
      @include respond-to('prominent') {
        width: 54px;
      }
    }

    &__action-icon {
      // width: c(24);
      width: 24px;
      // height: c(24);
      height: 24px;
      // // From 1280px width
      @include respond-to('medium') {
        width: m(24);
        height: m(24);
      }

      // // From 1920px width
      @include respond-to('prominent') {
        width: 36px;
        height: 36px;
      }
    }

    &__share {
      position: absolute;
      top: 42px;
      right: 0;
      background-color: rgba(245, 245, 245, 1);
      border-radius: 19px;
      opacity: 0.95;
      // // From 720px width
      @include respond-to('custom') {
        right: 150px;
        font-size: 14px;
        line-height: 10px;
      }
      // // From 1280px width
      @include respond-to('medium') {
        width: m(150);
        height: m(128);
        top: m(42);
        right: 0;
        border-radius: m(19);
      }

      // // From 1920px width
      @include respond-to('prominent') {
        width: 225px;
        height: 192px;
        top: 63px;
        border-radius: 28.5px;
      }
    }

    &__material {
      position: relative;
      width: 100%;
      background-color: rgba(72, 72, 72, 0.7);
      // border-radius: c(12);
      border-radius: 20px;
      cursor: pointer;
      overflow: hidden;
      // // From 1280px width
      @include respond-to('medium') {
        // border-radius: m(20);
        border-radius: 20px;
      }

      // // From 1920px width
      @include respond-to('prominent') {
        border-radius: 20px;
      }

      &:hover {
        opacity: 0.9;
      }
    }

    &__description {
      // padding: c(15);
      padding: 15px;
      font-family: var(--ft-ui4);
      // // From 1280px width
      @include respond-to('medium') {
        padding: m(15);
      }

      // // From 1920px width
      @include respond-to('prominent') {
        padding: 22.5px;
      }
    }

    &__description-copy {
      display: block;
      overflow: hidden;
      // font-size: c(12);
      font-size: 14px;
      // line-height: c(18);
      line-height: 20px;
      // // From 1280px width
      @include respond-to('medium') {
        font-size: m(14);
        line-height: m(20);
      }

      // // From 1920px width
      @include respond-to('prominent') {
        font-size: 21px;
        line-height: 30px;
      }

      &.truncated {
        display: -webkit-box;
        -webkit-line-clamp: 10;
        &::before {
          content: '';
          float: right;
          height: calc((10 - 1) * 1lh);
        }
      }
    }

    &__description-link {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1;
    }
  }

  .branding {
    &__container {
      // width: c(168);
      width: 168px;
      // // From 1280px width
      @include respond-to('medium') {
        width: m(325);
      }

      // // From 1920px width
      @include respond-to('prominent') {
        width: 487.5px;
      }
    }

    &__header {
      flex-direction: column;
      // justify-content: space-between;
      align-items: center;
      // width: c(100);
      width: 100px;
      // // min-height: c(128);
      // min-height: 128px;
      height: 128px;
      // margin-bottom: c(25);
      margin-bottom: 25px;
// border: 0.25px solid aquamarine;
      // // From 1280px width
      @include respond-to('medium') {
        flex-direction: row;
        // width: m(286);
        // width: m(295.5);
        width: m(305); // // NOTE: Need specs
        // // min-height: m(128);
        // min-height: unset;
        // height: m(28);
        height: m(36);
        margin-bottom: m(25);
      }

      // // From 1920px width
      @include respond-to('prominent') {
        // width: 457.5px;
        width: 456px;
        height: 54px;
        margin-bottom: 38px;
      }
    }

    &__logo {
      // width: c(33);
      width: 33px;
      // height: c(36);
      height: 36px;
      margin-right: unset;
      margin-bottom: 20px;
      // // From 1280px width
      @include respond-to('medium') {
        width: unset;
        height: 100%;
        aspect-ratio: 1/1;
        // margin-right: m(6);
        margin-right: m(5.83);
        margin-bottom: unset;
      }

      // // From 1920px width
      @include respond-to('prominent') {
        margin-right: 8.75px;
      }
    }

    &__title {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 37px;
      margin-right: unset;
      margin-bottom: 14px;
// border: 0.25px solid magenta;
      // // From 1280px width
      @include respond-to('medium') {
      flex-direction: row;
        height: m(15);
        // margin-right: m(6);
        margin-right: m(5.83);
        margin-bottom: unset;
// border: 0.25px solid cyan;
      }

      // // From 1920px width
      @include respond-to('prominent') {
        height: 22.5px;
        margin-right: 8.75px;
// border: 0.25px solid yellow;
      }
    }

    &__title-copy {
      padding-top: 0;
      // font-size: c(21);
      font-size: 21px;
      line-height: 20.5px;
      // // From 1280px width
      @include respond-to('medium') {
        padding-top: m(4);
        font-size: m(21);
        line-height: unset;
      }

      // // From 1920px width
      @include respond-to('prominent') {
        padding-top: 6px;
        font-size: 31.5px;
      }
    }

    &__badge-label {
      // padding: c(5) c(10) c(2);
      padding: 5px 10px 2px;
      // margin: 8px 0 0;
      // font-size: c(11);
      font-size: 11px;
      // border: c(1.85) solid;
      border: 1.85px solid;
      // border-radius: c(13);
      border-radius: 13px;
      // // From 1280px width
      @include respond-to('medium') {
        padding: m(5) m(10) m(2);
        margin: unset;
        font-size: m(11);
        border: m(1.85) solid;
        border-radius: m(13);
      }

      // // From 1920px width
      @include respond-to('prominent') {
        padding: 7.5px 15px 3px;
        font-size: 16.5px;
        border: 2px solid;
        border-radius: 19.5px;
      }
    }

    &__subtitle,
    &__copy {
      // width: c(168);
      width: 168px;
      // // font-size: c(12);
      // font-size: 12px;
      font-size: 14px;
      // line-height: c(18);
      line-height: 18px;
      // // From 1280px width
      @include respond-to('medium') {
        width: m(325);
        font-size: m(14);
        line-height: m(20);
      }

      // // From 1920px width
      @include respond-to('prominent') {
        width: 488px;
        font-size: 21px;
        line-height: 30px;
      }
    }

    &__link {
      display: flex;
      justify-content: center;
      align-items: center;
      // width: c(76);
      width: 76px;
      // height: c(12);
      height: 12px;
      // margin-top: c(10);
      margin-top: 10px;
      // font-size: c(12);
      font-size: 12px;
      // // From 1280px width
      @include respond-to('medium') {
        width: m(90);
        height: m(14);
        margin-top: m(9);
        font-size: m(14);
      }

      // // From 1920px width
      @include respond-to('prominent') {
        width: 133px;
        height: 21px;
        margin-top: 13.5px;
        font-size: 21px;
      }
    }

    &__continue-chevron {
      // width: c(12);
      width: 12px;
      // height: c(12);
      height: 12px;
      // padding: 0 0 0 c(2);
      padding: 0 0 0 2px;
      // // From 1280px width
      @include respond-to('medium') {
        width: m(14);
        height: m(14);
        padding: 0 0 0 m(2);
      }

      // // From 1920px width
      @include respond-to('prominent') {
        width: 21px;
        height: 21px;
        padding: 0 0 0 3px;
      }
    }

    &__download {
      // width: c(168);
      width: 168px;
      // min-height: c(242);
      min-height: 242px;
      // margin-top: c(43);
      margin-top: 43px;
      // // From 1280px width
      @include respond-to('medium') {
        width: m(168);
        min-height: m(242);
        margin-top: m(45);
      }

      // // From 1920px width
      @include respond-to('prominent') {
        width: 252px;
        min-height: 363px;
        margin-top: 67.5px;
      }
    }

    &__button-holder {
      // margin-top: c(-16);
      margin-top: -16px;
      // // From 1280px width
      @include respond-to('medium') {
        margin-top: m(-16);
      }

      // // From 1920px width
      @include respond-to('prominent') {
        margin-top: -24px;
      }

      .button-text {
        // width: c(112);
        width: 112px;
        // height: c(32);
        height: 32px;
        // // From 1280px width
        @include respond-to('medium') {
          width: m(112);
          height: m(32);
          // margin-top: m(-16);
          border-radius: m(16);
        }

        // // From 1920px width
        @include respond-to('prominent') {
          width: 168px;
          height: 47.61px;
          // margin-top: -24px;
          border-radius: 24px;
        }

        &__copy {
          // font-size: c(14);
          font-size: 14px;
          // line-height: c(10);
          line-height: 10px;
          // // From 1280px width
          @include respond-to('medium') {
            font-size: m(14);
            line-height: m(10);
          }

          // // From 1920px width
          @include respond-to('prominent') {
            font-size: 21px;
            line-height: 15px;
          }
        }
      }
    }

    .branding-showroom {
      &__wrapper {}

      &__caption {}

      &__copy {}

      &__qr-code {}
    }
  }
}

.viewer-read-more {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(60, 60, 60, 1);
  z-index: 11;
  &__container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% - 40px);
    height: calc(100% - 20px);
  }

  &__header {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 36px;
  }

  &__action {
    width: 36px;
    height: 36px;
  }

  &__action-bubble {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: rgba(34, 34, 34, 1);
    border-radius: 50%;
    overflow: hidden;
  }

  &__action-icon {
    width: 24px;
    height: 24px;
  }

  &__text-container {
    display: flex;
    justify-content: center;
    width: 480px;
    height: 100%;
  }

  &__description {
    width: 410px;
    height: 100%;
  }

  &__description-copy {
    font-family: var(--ft-ui4);
    font-size: 14px;
    line-height: 22px;
    color: rgba(255, 255, 255, 1);
    // // From 1280px width
    @include respond-to('medium') {
      font-size: 16px;
      line-height: 24px;
    }
  }
}

.viewer-overlay-mobile {
  position: absolute;
  // min-width: 100%;
  max-height: 100vh;
  max-height: 90vh;
  max-height: 87vh;
  aspect-ratio: 9/16; // // TODO: Landscape form work
  margin: 0 auto;
  right: 0;
  left: 0;
  z-index: 5;
  // // From 720px width
  @include respond-to('custom') {
    display: none;
    position: unset;
    // min-width: unset;
    max-height: unset;
    // aspect-ratio: unset;
  }

  &__header {
    position: relative;
    width: 100%;
    height: 150px;
    font-family: var(--ft-sBl);
    color: var(--clr-primary-lght);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.4) 35%, rgba(0, 0, 0, 0.3) 70%, rgba(0, 0, 0, 0) 100%);
    z-index: 5;
  }

  &__top {
    position: relative;
    display: flex;
    align-items: center;
    // width: 222px;
    width: 228px;
    // min-height: 27px;
    height: 27px;
    top: 24px;
    left: 15px;
    // // padding: 24px 0 0 20px;
    // padding: 24px 0 0 15px;
// border: 0.25px solid darkred;
  }

  &__logo {
    height: 100%;
    aspect-ratio: 1/1;
    // margin-right: 5px;
    margin-right: 4.5px;
  }

  &__headline {
    display: flex;
    align-items: center;
    // height: 11px;
    // height: 10.75px;
    // height: 11.25px;
    height: 12px;
    // padding-top: 1px;
    // margin-right: 6px;
    margin-right: 5px;
// border: 0.25px solid magenta;
  }

  &__headline-copy {
    padding-top: 3px;
    // font-size: 15.6px;
    font-size: 16px;
  }

  &__badge-label {
    padding: 1px 4px 0px;
    // padding: 2px 7px 4.5px 8px;
    font-size: 8.25px;
    // font-size: 10px;
    // line-height: 8.87px;
    color: var(--clr-primary-drk);
    background-color: var(--clr-primary-lght);
    border: 1.85px solid var(--clr-primary-lght);
    border-radius: 13px;
  }

  &__body-click-field,
  &__footer-click-field {
    position: absolute;
    width: 100%;
    bottom: 0;
  }

  &__body-click-field {
    height: calc(100% - 150px);
  }

  &__footer-click-field {
    height: 100%;
  }

  &__footer {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px 0 15px;
    right: 0;
    bottom: 0;
    left: 0;
    color: var(--clr-primary-lght);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.4) 35%, rgba(0, 0, 0, 0.3) 70%, rgba(0, 0, 0, 0) 100%);
    z-index: 10;
  }

  &__shelf,
  &__material,
  &__now-go {
    width: calc(100% - 30px);
  }

  &__shelf,
  &__publisher,
  &__action,
  &__material {
    display: flex;
    align-items: center;
  }

  &__shelf {
    justify-content: space-between;
    height: 36px;
    margin-bottom: 15px;
  }

  &__publisher,
  &__action {
    height: 100%;
    z-index: 0;
  }

  &__publisher {
    max-width: calc(100% - 87px);
    overflow: hidden;
  }

  &__publisher-icon,
  &__action-bubble {
    height: 100%;
    border-radius: 50%;
  }

  &__publisher-name {
    margin-left: 10px;
    font-family: var(--ft-ui6);
    font-size: 16px;
    line-height: 20px;
  }

  &__action {
    display: flex;
    justify-content: space-between;
    width: 87px;
  }

  &__action-bubble {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    background-color: rgba(72, 72, 72, 0.5);
    overflow: hidden;
  }

  &__action-icon {
    width: 24px;
    height: 24px;
  }

  &__share {
    position: absolute;
    top: -114px;
    right: 166px;
    background-color: rgba(245, 245, 245, 1);
    border-radius: 19px;
    opacity: 0.95;
  }

  &__material,
  &__now-go {
    border-radius: 18px;
  }

  &__material {
    position: relative;
    min-height: 36px;
    margin-bottom: 55px;
    margin-bottom: 10px;
    background-color: rgba(72, 72, 72, 0.7);
    cursor: pointer;
    overflow: hidden;
    &:hover {
      opacity: 0.9;
    }
  }

  &__description,
  &__marketing {
    font-family: var(--ft-ui4);
  }

  &__description {
    padding: 15px 20px;
  }

  &__now-go {
    display: flex;
    justify-content: center;
    bottom: 15px;
    background-color: var(--clr-accent-trnsprnt);
  }

  &__marketing {
    display: flex;
    align-items: center;
    width: calc(100% - 30px);
    padding: 10px 0;
  }

  &__marketing-caption {}

  &__description-copy,
  &__marketing-copy {
    font-family: var(--ft-ui4);
    font-size: 14px;
    line-height: 20px;
  }

  &__description-copy {
    display: block;
    overflow: hidden;

    &.truncated {
      -webkit-line-clamp: 5;
      &::before {
        content: '';
        float: right;
        height: calc((5 - 1) * 1lh);
      }
    }
  }

  &__description-link {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .button-text {
    width: auto;
    height: unset;
    padding: 8px 16px;
    margin: 0 0 0 auto;
    &--learn {
      min-width: 102.58px;
    }

    &--try {
      min-width: 63.11px;
    }

    &__copy {
      white-space: nowrap;
    }
  }
}

.share {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  top: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 5;

  &__modal {
    z-index: 6;
  }
}

.truncated {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

.show-more {
  position: relative;
  font-family: var(--ft-ui7);
  clear: both;
  float: right;
  cursor: pointer;
  color: rgba(255, 255, 255, 1);
  z-index: 2;
}
