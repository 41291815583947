@use '../../assets/styles/main' as *;

.modal-list {
  position: absolute;
  // display: flex;
  // align-items: center;
  width: 150px;
  height: 128px;
  // top: 42px;
  // right: 0;
  background-color: rgba(245, 245, 245, 1);
  border-radius: 19px;
  opacity: 0.95;
  // // From 1280px width
  @include respond-to('medium') {
    width: m(150);
    height: m(128);
    // top: m(42);
    border-radius: m(19);
  }

  // // From 1920px width
  @include respond-to('prominent') {
    width: 225px;
    height: 192px;
    // top: 63px;
    border-radius: 28.5px;
  }

  &__container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 3;
  }

  &__options {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 84.375%;
    font-family: var(--ft-ui5);
  }

  .react-share__ShareButton,
  &__item {
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }

  .react-share__ShareButton {
    height: 33.333%;
  }

  &__item {
    height: 100%;
    color: rgba(51, 51, 51, 1);
    &--link-copied {
      color: rgba(40, 176, 0, 1);
    }
  }

  &__icon,
  &__label {
    position: relative;
  }

  &__icon {
    width: 18px;
    height: 18px;
    left: 18px;
    // // From 1280px width
    @include respond-to('medium') {
      width: m(18);
      height: m(18);
      left: m(18);
    }

    // // From 1920px width
    @include respond-to('prominent') {
      width: 27px;
      height: 27px;
      left: 27px;
    }
  }

  &__label {
    margin-left: 27px;
    font-size: 14px;
    // // From 1280px width
    @include respond-to('medium') {
      margin-left: m(27);
      font-size: m(14);
    }

    // // From 1920px width
    @include respond-to('prominent') {
      margin-left: 40.5px;
      font-size: 21px;
    }
  }

  &__scrim {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: red;
    z-index: 2;
  }
}
