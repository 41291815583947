@use '../../assets/styles/main' as *;

@keyframes grow {
  0% {
    transform: scale(1);
    background-color: rgba(200, 200, 200, 0.5);
    z-index: 2;
  }
  100% {
    // background-color: rgba(200, 200, 200, 0.3);
    background-color: rgba(200, 200, 200, 0);
    opacity: 0;
    transform: scale(30);
    z-index: -1;
  }
}

@keyframes vanish {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.interaction-effect {
  position: absolute;
  position: inherit;
  width: 100%;
  height: 100%;
  &__impact {
    position: absolute;
    width: 25px;
    height: 25px;
    top: 50%;
    left: 50%;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    pointer-events: none;
    transform: scale(0);
  }

  &__invert {
    width: 100%;
    height: 100%;
    filter: invert(1);
  }

  &--grow {
    animation: grow 0.75s ease-in-out;
    // animation: grow 0.75s ease-out;
  }

  &--vanish {
    animation: vanish 0.75s ease-in-out;
    // animation: vanish 0.75s ease-out;
  }
}


