// _core.scss

// // Basic yet global style setup

// // Make all elements from the DOM inherit from the root box-sizing
// *,
// *::before,
// *::after {
//   box-sizing: inherit;
// }

// * {
//   // // Hide scrollbars for Gecko
//   scrollbar-width: none;
//   // // Hide scrollbars for Chromium/Webkit/Trident
//   &::-webkit-scrollbar {
//     display: none;
//   }
// }

html {
  // // Setup box model of border-box on the root element
  // box-sizing: border-box;
  // width: 100%;
  width: 100vw;
  height: 100vh;
  // font-family: sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  overflow: hidden;
}

// @media screen and (orientation:landscape) {
//   html {
//     // width: 100vh;
//     height: 100vw;
//     -ms-transform: rotate(-90deg); // //IE 9
//     -webkit-transform: rotate(-90deg); // // Chrome, Safari, Opera
//     transform: rotate(-90deg);
//     overflow: scroll;
//   }
// }

body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // // Removes highest level overflow scrolling
  // overflow-y: hidden;
  touch-action: pan-x pan-y;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  font-family: var(--ft-ui7);
  // color: inherit;
  color: var(--clr-accent);
  background-color: transparent;
  text-decoration: none;
}

b,
strong {
  font-weight: bold;
}

blockquote,
dfn {
  font-style: italic;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul,
blockquote,
button,
figure {
  margin: 0;
  padding: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
}

iframe,
img {
  border: 0;
}

img {
  height: auto;
  max-width: 100%;
}

li {
  display: block;
  list-style: none;
}

// sub,
// sup {
//   font-size: $size-t0;
//   line-height: 0;
//   position: relative;
//   vertical-align: baseline;
// }
