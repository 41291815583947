@use 'assets/styles/main' as *;

.App {
  height: 100%;
  .loading {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    background-color: rgba(33, 33, 33, 0.4);
    background-color: rgba(27, 27, 27, 0.4);
    z-index: 4;
    &__container {
      width: 125px;
      height: 125px;
    }
  }
}

.App-paragraph {
  color: var(--clr-primary-lght);
}
