// @use '../../assets/styles/main' as *;

// // NOTE: Galaxy Store team's code, do not alter the following
// //   - SmartBanner scripting
// //   - SmartBanner structure
// //   - SmartBanner styling

// // SmartBanner styling

.smdiv {
  display: flex;
  color: white;
  flex-direction: column;
  background-color: #0F0F0F;
}

.sm_banner_maindiv {
  display: flex;
  color: white;
  flex-direction: column;
  background-color: #0F0F0F;
}

.sm_banner_appbanner {
  display: flex;
  padding: 16px 16px 1px 16px;
  justify-content: space-between;
}

.sm_banner_appbanner1 {
  display: flex;
  padding: 16px 16px 5px 16px;
}

.sm_banner_bannerclose {
  display: flex;
  align-items: center;
}

.sm_banner_closebtn {
  height: 10px;
}

.sm_banner_appLogo {
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.sm_banner_applogoImage {
  height: 45px;
  border-radius: 10px;
}

.sm_banner_appinfo {
  flex-direction: column;
  display: flex;
  flex: 1;
  flex-wrap: nowrap;
  font-weight: 400;
  margin-top: -1px;
  min-width: 0;
  margin-right: 18px;
  padding-left: 10px;
}

.sm_banner_title {
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.sm_banner_statement {
  font-size: 10px;
  font-family: "Roboto", sans-serif;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.sm_banner_app_rating {
  display: flex;
  align-content: center;
  align-items: center;
  font-family: "Roboto", sans-serif;
  white-space: nowrap;
}

.sm_banner_star {
  height: 10px;
  margin-top: -2px;
}

.sm_banner_count {
  display: flex;
  align-content: center;
  font-size: 9px;
}

.sm_banner_rightsec {
  display: flex;
  flex-direction: column;
}

.sm_banner_insbtn {
  margin-top: -2px;
  margin-bottom: 4px;
}

.sm_banner_ins {
  padding-top: 2px;
  height: 26px;
  width: 66px;
  margin-top: 2px
}

.sm_banner_galaxy_icon {
  height: 12px;
}

.sm_banner_borderLine {
  background-color: #222222;
  height: 1px;
  border: 0px;
}

.sm_banner_tc {
  display: flex;
  padding: 0px 16px 0px 16px;
  font-size: 9px;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0px 5px 0px;
  font-family: "Roboto", sans-serif;
}

.sm_banner_terms {
  padding-right: 10px;
}

.sm_banner_lower a {
  display: inline-flex;
  color: #1c9ee9;
  text-decoration: none;
}


@media only screen and (min-width: 320px) {
  .sm_banner_closebtn {
    height: 12px;
  }

  .sm_banner_applogoImage {
    height: 52px;
    border-radius: 10px;
  }

  .sm_banner_title {
    font-size: 15px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;

    overflow: hidden;
  }

  .sm_banner_statement {
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    white-space: nowrap;
    text-overflow: ellipsis;

    overflow: hidden;
  }

  .sm_banner_star {
    height: 11px;
    margin-top: -2px;
  }

  .sm_banner_count {
    display: flex;
    align-content: center;
    font-size: 12px;
  }

  .sm_banner_ins {
    padding-top: 2px;
    height: 33px;
    width: 100px;
    margin-top: 2px
  }

  .sm_banner_galaxy_icon {
    height: 15px;
  }

  .sm_banner_tc {
    display: flex;
    padding: 0px 16px 0px 16px;
    font-size: 11px;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0px 5px 0px;
    font-family: "Roboto", sans-serif;
  }

  .sm_banner_terms {
    padding-right: 10px;
  }

  .sm_banner_lower a {
    display: inline-flex;
    color: #1c9ee9;
    text-decoration: none;
  }
}

// // NOTE: Modified Smart Banner code for Prod begin
.sm_banner_terms {
  width: 75%;
}

.sm_banner_icon1 {
  display: flex;
  justify-content: flex-end;
  width: 25%;
}

.sm_banner_galaxy_icon {
  height: fit-content;
}
// // NOTE: Modified Smart Banner code for Prod end
