// _variables.scss
@use 'sass:math';
// @use 'config' as *;
@use 'mixins' as *;

// // Global variable assignments

// // Scaling & ratio assignments (only use SASS variables when necessary)
$s: calc(100vw / 1920);
$w: 100vw;
$h: calc(100vw / 16 * 9);

// --focus-in-1: cubic-bezier(0.21, 1.5, 0.54, 1) 500ms;

:root {
  // Fade time for page transitions
  // This should match the value in view/src/utils/animationUtils.js
  --fade-time: 0.5s;

  // // Optimized Scaling assignments

  // //   - Width Declaration
  --w: 100vw;

  // //   - Height Factor
  --h: calc(100vw / 16 * 9);

  // //   - Scale Quotients
  // // @for _functions => a(), b(), c(), d(), l(), m(), p(), s(), t(), v()
  --a: calc(100vw / 480);
  --b: calc(100vw / 640);
  --c: calc(100vw / 720);
  --d: calc(100vw / 768);
  --l: calc(100vw / 960);
  --m: calc(100vw / 1280);
  --p: calc(100vw / 1920);
  --s: calc(100vw / 2560);
  --t: calc(100vw / 3840);
  --v: calc(100vw / 7680);

  // // Font-family stack assignments

  // //   - Samsung One UI
  --ft-ui2: SamsungOneUI200, SamsungOneUI-200;
  --ft-ui3: SamsungOneUI300, SamsungOneUI-300;
  --ft-ui4: SamsungOneUI400, SamsungOneUI-400;
  --ft-ui5: SamsungOneUI500, SamsungOneUI-500;
  --ft-ui6: SamsungOneUI600, SamsungOneUI-600;
  --ft-ui7: SamsungOneUI700, SamsungOneUI-700;
  --ft-ui8: SamsungOneUI800, SamsungOneUI-800;
  --ft-ui10: SamsungOneUI1000, SamsungOneUI-1000;

  // //   - Samsung Sharp Sans
  --ft-sRg: SamsungSharpSansRegular, SamsungSharpSans-Regular;
  --ft-sMd: SamsungSharpSansMedium, SamsungSharpSans-Medium;
  --ft-sBl: SamsungSharpSansBold, SamsungSharpSans-Bold;

  // //   - Samsung One Latin
  --ft-lt2: SamsungOneLatin200, SamsungOneLatin-200;
  --ft-ltc: SamsungOneLatin200C, SamsungOneLatin-200C;
  --ft-lt3: SamsungOneLatin300, SamsungOneLatin-300;
  --ft-lt3c: SamsungOneLatin300C, SamsungOneLatin-300C;
  --ft-lt4: SamsungOneLatin400, SamsungOneLatin-400;
  --ft-lt4c: SamsungOneLatin400C, SamsungOneLatin-400C;
  --ft-lt5: SamsungOneLatin500, SamsungOneLatin-500;
  --ft-lt5c: SamsungOneLatin500C, SamsungOneLatin-500C;
  --ft-lt6: SamsungOneLatin600, SamsungOneLatin-600;
  --ft-lt6c: SamsungOneLatin600C, SamsungOneLatin-600C;
  --ft-lt7: SamsungOneLatin700, SamsungOneLatin-700;
  --ft-lt7c: SamsungOneLatin700C, SamsungOneLatin-700C;
  --ft-lt8: SamsungOneLatin800, SamsungOneLatin-800;
  --ft-lt8c: SamsungOneLatin800C, SamsungOneLatin-800C;
  --ft-lt9: SamsungOneLatin900, SamsungOneLatin-900;
  --ft-lt9c: SamsungOneLatin900C, SamsungOneLatin-900C;
  --ft-lt10: SamsungOneLatin1000, SamsungOneLatin-1000;
  --ft-lt10c: SamsungOneLatin1000C, SamsungOneLatin-1000C;
  --ft-lt11: SamsungOneLatin1100, SamsungOneLatin-1100;
  --ft-lt11c: SamsungOneLatin1100C, SamsungOneLatin-1100C;

  // // Color assignments
  --clr-primary-drk: rgba(0, 0, 0, 1);
  --clr-primary-lght: rgba(255, 255, 255, 1);
  --clr-secondary: rgba(34, 34, 34, 1);
  --clr-accent: rgba(255, 82, 69, 1);
  --clr-accent-trnsprnt: rgba(255, 82, 69, 0.75);

  // // Screen size specific assignments
  // // @see _config => $breakpoints

  // // From 480px width
  @include respond-to('atomic') {
    --ratio-label: 'atomic';
    --ratio-test-color: yellow;
    // --ss: calc(100vw / 480);
    // --hh: calc(100vw / 3 * 2);
  }

  // // From 640px width
  @include respond-to('bitty') {
    --ratio-label: 'bitty';
    --ratio-test-color: black;
    // --ss: calc(100vw / 640);
    // --hh: calc(100vw / 4 * 3);
  }

  // // From 768px width
  @include respond-to('diminutive') {
    --ratio-label: 'diminutive';
    --ratio-test-color: magenta;
    // --ss: calc(100vw / 768);
    // --hh: calc(100vw / 16 * 10);
  }

  // // From 960px width
  @include respond-to('little') {
    --ratio-label: 'little';
    --ratio-test-color: brown;
    // --ss: calc(100vw / 960);
    // --hh: calc(100vw / 16 * 9);
  }

  // // From 1280px width
  @include respond-to('medium') {
    --ratio-label: 'medium';
    --ratio-test-color: lime;
    // --ss: calc(100vw / 1280);
    // --hh: calc(100vw / 16 * 9);
  }

  // // From 1920px width
  @include respond-to('prominent') {
    --ratio-label: 'prominent';
    --ratio-test-color: cyan;
    // --ss: calc(100vw / 1920);
    // --hh: calc(100vw / 16 * 9);
  }

  // // From 2560px width
  @include respond-to('substantial') {
    --ratio-label: 'substantial';
    --ratio-test-color: red;
    // --ss: calc(100vw / 2560);
    // --hh: calc(100vw / 16 * 9);
  }

  // // From 3840px width
  @include respond-to('tremendous') {
    --ratio-label: 'tremendous';
    --ratio-test-color: purple;
    // --ss: calc(100vw / 3840);
    // --hh: calc(100vw / 16 * 9);
  }

  // // From 7680px width
  @include respond-to('vast') {
    --ratio-label: 'vast';
    --ratio-test-color: tan;
    // --ss: calc(100vw / 7680);
    // --hh: calc(100vw / 16 * 9);
  }
}
