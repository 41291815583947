@use '../../assets/styles/main' as *;

.branding {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // width: 100%;
  height: 100%;
  color: var(--clr-primary-lght);
  &__container {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    align-items: center;
    width: 305px;
    // height: 280px;
    // border: 1px solid lime;
    // // From 1280px width
    @include respond-to('medium') {
      // width: 325px;
      // height: 162px;
      // //
      width: m(445);
      // height: m(162);
      // border: 1px solid saddlebrown;
    }

    // // From 1920px width
    @include respond-to('prominent') {
      width: 668px;
      // height: 243px;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    width: 286px;
    width: 296px;
    width: 295.6px;
    width: 295.52px;
    width: 305px;
    height: 36px;
    margin-bottom: 33.5px;
    font-family: var(--ft-sBl);
    // text-align: center;
// border: 0.25px solid aquamarine;
    // // From 1280px width
    @include respond-to('medium') {
      flex-direction: row;
      // justify-content: space-between;
      width: m(305);
      max-width: unset;
      height: m(36);
      margin-bottom: m(33.5);
// border: 1px solid darkred;
    }

    // // From 1920px width
    @include respond-to('prominent') {
      width: 457.5px;
      height: 54px;
      margin-bottom: 50.25px;
// border: 1px solid yellow;
    }
  }

  &__logo {
    height: 100%;
    aspect-ratio: 1/1;
    // margin-right: 6px;
    margin-right: 5.83px;

    // // From 1280px width
    @include respond-to('medium') {
      // margin-right: m(6);
      margin-right: m(5.83);
    }

    // // From 1920px width
    @include respond-to('prominent') {
      margin-right: 8.75px;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    height: 15px;
    // margin-right: 6px;
    margin-right: 5.83px;
// border: 0.25px solid magenta;
    // // From 1280px width
    @include respond-to('medium') {
      height: m(15);
      // margin-right: m(6);
      margin-right: m(5.83);
// border: 0.25px solid cyan;
    }

    // // From 1920px width
    @include respond-to('prominent') {
      height: 22.5px;
      margin-right: 8.75px;
// border: 0.25px solid yellow;
    }
  }

  &__title-copy {
    padding-top: 4px;
    font-size: 21px;
// border: 1px solid papayawhip;
    // // From 1280px width
    @include respond-to('medium') {
      padding-top: m(4);
      font-size: m(21);
    }

    // // From 1920px width
    @include respond-to('prominent') {
      padding-top: 6px;
      font-size: 31.5px;
    }
  }

  &__badge-label {
    padding: 5px 10px 2px;
    font-size: 11px;
    color: rgba(255, 255, 255, 0.5);
    border: 1.85px solid;
    border-radius: 13px;
    // // From 1280px width
    @include respond-to('medium') {
      padding: m(5) m(10) m(2);
      font-size: m(11);
      border: m(1.85) solid;
      border-radius: m(13);
    }

    // // From 1920px width
    @include respond-to('prominent') {
      padding: 7.5px 15px 3px;
      font-size: 16.5px;
      border: 2px solid;
      border-radius: 19.5px;
    }
  }

  &__details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    // height: 124px;
    // border: 1px solid coral;
    // // From 1280px width
    @include respond-to('medium') {
      // //
      // height: m(101);
    }

    // // From 1920px width
    @include respond-to('prominent') {
      // height: 151.5px;
    }
  }

  &__subtitle,
  &__copy,
  &__link {
    text-align: center;
  }

  &__subtitle,
  &__copy {
    color: var(--clr-primary-lght);
  }

  &__subtitle {
    font-family: var(--ft-ui5);
    font-size: 16px;
    // // From 1280px width
    @include respond-to('medium') {
      font-size: m(16);
    }

    // // From 1920px width
    @include respond-to('prominent') {
      font-size: 24px;
    }
  }

  &__copy {
    // // From smallest width
    width: 100%;
    margin-top: 19px;
    font-family: var(--ft-ui4);
    font-size: 12px;
    line-height: 18px;
    // border: 1px solid firebrick;
    // // From 1280px width
    @include respond-to('medium') {
      // font-family: var(--ft-ui4);
      // //
      margin-top: m(19);
      font-size: m(14);
        font-size: m(12);
      line-height: m(20);

      // border: 1px solid cyan;
    }

    // // From 1920px width
    @include respond-to('prominent') {
      margin-top: 28.5px;
      font-size: 18px;
      line-height: 30px;
    }
  }

  &__link {
    margin-top: 24px;
    font-size: 12px;
    // // From 1280px width
    @include respond-to('medium') {
      margin-top: m(24);
      font-size: m(14);
    }

    // // From 1920px width
    @include respond-to('prominent') {
      margin-top: 36px;
      font-size: 21px;
    }
  }

  &__download {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 168px;
    min-height: 242px;
    margin-top: 45px;
    // border: 1px solid paleturquoise;
    // // From 1280px width
    @include respond-to('medium') {
      width: m(168);
      min-height: m(242);
      margin-top: m(45);
    }

    // // From 1920px width
    @include respond-to('prominent') {
      width: 252px;
      min-height: 363px;
      margin-top: 67.5px;
    }
  }

  &__button-holder {
    position: absolute;
    margin-top: -16px;
    // // From 1280px width
    @include respond-to('medium') {
      margin-top: m(-16);
    }

    // // From 1920px width
    @include respond-to('prominent') {
      margin-top: -24px;
    }

    .button-text {
      width: 112px;
      height: 32px;
      // // From 1280px width
      @include respond-to('medium') {
        width: m(112);
        height: m(32);
        // margin-top: m(-16);
        border-radius: m(16);
      }

      // // From 1920px width
      @include respond-to('prominent') {
        width: 168px;
        height: 47.61px;
        // margin-top: -24px;
        border-radius: 24px;
      }

      &__copy {
        font-size: 14px;
        line-height: 10px;
        // // From 1280px width
        @include respond-to('medium') {
          font-size: m(14);
          line-height: m(10);
        }

        // // From 1920px width
        @include respond-to('prominent') {
          font-size: 21px;
          line-height: 15px;
        }
      }
    }
  }

  &__footer {
    width: 212px;
    height: 12px;
    margin-top: 15px;
          // border: 1px solid cyan;
    // // From 1280px width
    @include respond-to('medium') {
      width: m(212);
      height: m(12);
      margin-top: m(15);
    }

    // // From 1920px width
    @include respond-to('prominent') {
      width: 318px;
      height: 18px;
      margin-top: 22.5px;
    }
  }

  &__legal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  &__doc {
    font-family: var(--ft-ui5);
    font-size: 12px;
    color: var(--clr-primary-lght);
    // // From 1280px width
    @include respond-to('medium') {
      font-size: m(10);
    }

    // // From 1920px width
    @include respond-to('prominent') {
      font-size: 15px;
    }
  }
}

.branding-showroom {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 226px;
  // background-color: rgba(197, 197, 197, 0.5);
  background-color: rgba(197, 197, 197, 0.2);
  border-radius: 20px;
  // border: 1px solid red;
  // // From 1280px width
  @include respond-to('medium') {
    height: m(226);
  }

  // // From 1920px width
  @include respond-to('prominent') {
    height: 339px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 140px;
    height: 180px;
    margin-top: 26px;
    // border: 1px solid whitesmoke;
    // // From 1280px width
    @include respond-to('medium') {
      width: m(140);
      height: m(180);
      margin-top: m(26);
    }

    // // From 1920px width
    @include respond-to('prominent') {
      width: 210px;
      height: 261px;
      margin-top: 39px;
    }
  }

  &__caption {
    text-align: center;
  }

  &__copy {
    font-family: var(--ft-ui4);
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: rgba(255, 255, 255, 0.75);
    // // From 1280px width
    @include respond-to('medium') {
      font-size: m(12);
      line-height: m(16);
    }

    // // From 1920px width
    @include respond-to('prominent') {
      font-size: 18px;
      line-height: 24px;
    }
  }

  &__qr-code {
    width: 80px;
    height: 80px;
    // // From 1280px width
    @include respond-to('medium') {
      width: m(80);
      height: m(80);
    }

    // // From 1920px width
    @include respond-to('prominent') {
      width: 120px;
      height: 120px;
    }
  }

  &__get-it {
    width: 100%;
    cursor: pointer;
  }
}
