// _helpers.scss

// // Frequently used declarations

.is-gone {
  display: none !important;
}

.is-hidden {
  visibility: hidden;
}

.no-touch {
  touch-action: none;
}

.overflow-hidden {
  &-x {
    overflow-x: hidden;
  }

  &-y {
    overflow-y: hidden;
  }

  &-xy {
    overflow: hidden;
  }
}

@keyframes popIn {
  0% {
    transform: scale(0.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes popOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.1);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.pop-fade-effect {
  &--in {
    animation: popIn 200ms cubic-bezier(0.34, 1.56, 0.64, 1), fadeIn 200ms ease-in-out;
  }

  &--out {
    animation: popOut 101ms ease-in, fadeOut 101ms ease-in-out;
  }
}
