@use '../../assets/styles/main' as *;

.learn-more {
  width: 100%;
  height: 100%;
  background-color: var(--clr-secondary);
}

.smdiv,
.sm_banner_maindiv {
  position: absolute;
  width: 100%;
}
