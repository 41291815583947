// _glyphs.scss

// // All font and icon @font-face declarations

// // Roboto

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

// // SF Condensed

@font-face {
  font-family: 'SFCondensed-Ultralight';
  src: url('/assets/fonts/sf-condensed/SFUIDisplayCondensed-Ultralight.otf');
}

@font-face {
  font-family: 'SFCondensed-Thin';
  src: url('/assets/fonts/sf-condensed/SFUIDisplayCondensed-Thin.otf');
}

@font-face {
  font-family: 'SFCondensed-Light';
  src: url('/assets/fonts/sf-condensed/SFUIDisplayCondensed-Light.otf');
}

@font-face {
  font-family: 'SFCondensed';
  src: url('/assets/fonts/sf-condensed/SFUIDisplayCondensed-Regular.otf');
}
@font-face {
  font-family: 'SFCondensed-Regular';
  src: url('/assets/fonts/sf-condensed/SFUIDisplayCondensed-Regular.otf');
}

@font-face {
  font-family: 'SFCondensed-Medium';
  src: url('/assets/fonts/sf-condensed/SFUIDisplayCondensed-Medium.otf');
}

@font-face {
  font-family: 'SFCondensed-Semibold';
  src: url('/assets/fonts/sf-condensed/SFUIDisplayCondensed-Semibold.otf');
}

@font-face {
  font-family: 'SFCondensed-Bold';
  src: url('/assets/fonts/sf-condensed/SFUIDisplayCondensed-Bold.otf');
}

@font-face {
  font-family: 'SFCondensed-Heavy';
  src: url('/assets/fonts/sf-condensed/SFUIDisplayCondensed-Heavy.otf');
}

@font-face {
  font-family: 'SFCondensed-Black';
  src: url('/assets/fonts/sf-condensed/SFUIDisplayCondensed-Black.otf');
}

// // Samsung One UI

@font-face {
  font-family: SamsungOneUI200;
  src: url('/assets/fonts/samsung-one-ui/samsung-one-ui-200.ttf');
}
@font-face {
  font-family: SamsungOneUI-200;
  src: url('/assets/fonts/samsung-one-ui/samsung-one-ui-200.ttf');
}

@font-face {
  font-family: SamsungOneUI300;
  src: url('/assets/fonts/samsung-one-ui/samsung-one-ui-300.ttf');
}
@font-face {
  font-family: SamsungOneUI-300;
  src: url('/assets/fonts/samsung-one-ui/samsung-one-ui-300.ttf');
}

@font-face {
  font-family: SamsungOneUI400;
  src: url('/assets/fonts/samsung-one-ui/samsung-one-ui-400.ttf');
}
@font-face {
  font-family: SamsungOneUI-400;
  src: url('/assets/fonts/samsung-one-ui/samsung-one-ui-400.ttf');
}

@font-face {
  font-family: SamsungOneUI500;
  src: url('/assets/fonts/samsung-one-ui/samsung-one-ui-500.ttf');
}
@font-face {
  font-family: SamsungOneUI-500;
  src: url('/assets/fonts/samsung-one-ui/samsung-one-ui-500.ttf');
}

@font-face {
  font-family: SamsungOneUI600;
  src: url('/assets/fonts/samsung-one-ui/samsung-one-ui-600.ttf');
}
@font-face {
  font-family: SamsungOneUI-600;
  src: url('/assets/fonts/samsung-one-ui/samsung-one-ui-600.ttf');
}

@font-face {
  font-family: SamsungOneUI700;
  src: url('/assets/fonts/samsung-one-ui/samsung-one-ui-700.ttf');
}
@font-face {
  font-family: SamsungOneUI-700;
  src: url('/assets/fonts/samsung-one-ui/samsung-one-ui-700.ttf');
}

@font-face {
  font-family: SamsungOneUI800;
  src: url('/assets/fonts/samsung-one-ui/samsung-one-ui-800.ttf');
}
@font-face {
  font-family: SamsungOneUI-800;
  src: url('/assets/fonts/samsung-one-ui/samsung-one-ui-800.ttf');
}

@font-face {
  font-family: SamsungOneUI1000;
  src: url('/assets/fonts/samsung-one-ui/samsung-one-ui-1000.ttf');
}
@font-face {
  font-family: SamsungOneUI-1000;
  src: url('/assets/fonts/samsung-one-ui/samsung-one-ui-1000.ttf');
}

// // Samsung Sharp Sans

@font-face {
  font-family: SamsungSharpSansRegular;
  src: url('/assets/fonts/samsung-sharp-sans/samsung-sharp-sans-regular.ttf');
}
@font-face {
  font-family: SamsungSharpSans-Regular;
  src: url('/assets/fonts/samsung-sharp-sans/samsung-sharp-sans-regular.ttf');
}
@font-face {
  font-family: SamsungSharpSans;
  src: url('/assets/fonts/samsung-sharp-sans/samsung-sharp-sans-regular.ttf');
}
@font-face {
  font-family: 'Samsung Sharp Sans';
  src: url('/assets/fonts/samsung-sharp-sans/samsung-sharp-sans-regular.ttf');
}

@font-face {
  font-family: SamsungSharpSansMedium;
  src: url('/assets/fonts/samsung-sharp-sans/samsung-sharp-sans-medium.ttf');
}
@font-face {
  font-family: SamsungSharpSans-Medium;
  src: url('/assets/fonts/samsung-sharp-sans/samsung-sharp-sans-medium.ttf');
}

@font-face {
  font-family: SamsungSharpSansBold;
  src: url('/assets/fonts/samsung-sharp-sans/samsung-sharp-sans-bold.ttf');
}
@font-face {
  font-family: SamsungSharpSans-Bold;
  src: url('/assets/fonts/samsung-sharp-sans/samsung-sharp-sans-bold.ttf');
}

// // Samsung One Latin

@font-face {
  font-family: SamsungOneLatin200;
  src: url('/assets/fonts/samsung-one-latin/samsung-one-latin-200.ttf');
}
@font-face {
  font-family: SamsungOneLatin-200;
  src: url('/assets/fonts/samsung-one-latin/samsung-one-latin-200.ttf');
}

@font-face {
  font-family: SamsungOneLatin200C;
  src: url('/assets/fonts/samsung-one-latin/samsung-one-latin-200-c.ttf');
}
@font-face {
  font-family: SamsungOneLatin-200C;
  src: url('/assets/fonts/samsung-one-latin/samsung-one-latin-200-c.ttf');
}

@font-face {
  font-family: SamsungOneLatin300;
  src: url('/assets/fonts/samsung-one-latin/samsung-one-latin-400.ttf');
}
@font-face {
  font-family: SamsungOneLatin300;
  src: url('/assets/fonts/samsung-one-latin/samsung-one-latin-400.ttf');
}

@font-face {
  font-family: SamsungOneLatin400;
  src: url('/assets/fonts/samsung-one-latin/samsung-one-latin-400.ttf');
}
@font-face {
  font-family: SamsungOneLatin-400;
  src: url('/assets/fonts/samsung-one-latin/samsung-one-latin-400.ttf');
}

@font-face {
  font-family: SamsungOneLatin400C;
  src: url('/assets/fonts/samsung-one-latin/samsung-one-latin-400-c.ttf');
}
@font-face {
  font-family: SamsungOneLatin-400C;
  src: url('/assets/fonts/samsung-one-latin/samsung-one-latin-400-c.ttf');
}

@font-face {
  font-family: SamsungOneLatin500;
  src: url('/assets/fonts/samsung-one-latin/samsung-one-latin-500.ttf');
}
@font-face {
  font-family: SamsungOneLatin-500;
  src: url('/assets/fonts/samsung-one-latin/samsung-one-latin-500.ttf');
}

@font-face {
  font-family: SamsungOneLatin500C;
  src: url('/assets/fonts/samsung-one-latin/samsung-one-latin-500-c.ttf');
}
@font-face {
  font-family: SamsungOneLatin-500C;
  src: url('/assets/fonts/samsung-one-latin/samsung-one-latin-500-c.ttf');
}

@font-face {
  font-family: SamsungOneLatin600;
  src: url('/assets/fonts/samsung-one-latin/samsung-one-latin-600.ttf');
}
@font-face {
  font-family: SamsungOneLatin-600;
  src: url('/assets/fonts/samsung-one-latin/samsung-one-latin-600.ttf');
}

@font-face {
  font-family: SamsungOneLatin600C;
  src: url('/assets/fonts/samsung-one-latin/samsung-one-latin-600-c.ttf');
}
@font-face {
  font-family: SamsungOneLatin-600C;
  src: url('/assets/fonts/samsung-one-latin/samsung-one-latin-600-c.ttf');
}

@font-face {
  font-family: SamsungOneLatin700;
  src: url('/assets/fonts/samsung-one-latin/samsung-one-latin-700.ttf');
}
@font-face {
  font-family: SamsungOneLatin-700;
  src: url('/assets/fonts/samsung-one-latin/samsung-one-latin-700.ttf');
}

@font-face {
  font-family: SamsungOneLatin700C;
  src: url('/assets/fonts/samsung-one-latin/samsung-one-latin-700-c.ttf');
}
@font-face {
  font-family: SamsungOneLatin-700C;
  src: url('/assets/fonts/samsung-one-latin/samsung-one-latin-700-c.ttf');
}

@font-face {
  font-family: SamsungOneLatin800;
  src: url('/assets/fonts/samsung-one-latin/samsung-one-latin-800.ttf');
}
@font-face {
  font-family: SamsungOneLatin-800;
  src: url('/assets/fonts/samsung-one-latin/samsung-one-latin-800.ttf');
}

@font-face {
  font-family: SamsungOneLatin800C;
  src: url('/assets/fonts/samsung-one-latin/samsung-one-latin-800-c.ttf');
}
@font-face {
  font-family: SamsungOneLatin-800C;
  src: url('/assets/fonts/samsung-one-latin/samsung-one-latin-800-c.ttf');
}

@font-face {
  font-family: SamsungOneLatin900;
  src: url('/assets/fonts/samsung-one-latin/samsung-one-latin-900.ttf');
}
@font-face {
  font-family: SamsungOneLatin-900;
  src: url('/assets/fonts/samsung-one-latin/samsung-one-latin-900.ttf');
}

@font-face {
  font-family: SamsungOneLatin900C;
  src: url('/assets/fonts/samsung-one-latin/samsung-one-latin-900-c.ttf');
}
@font-face {
  font-family: SamsungOneLatin-900C;
  src: url('/assets/fonts/samsung-one-latin/samsung-one-latin-900-c.ttf');
}

@font-face {
  font-family: SamsungOneLatin1000;
  src: url('/assets/fonts/samsung-one-latin/samsung-one-latin-1000.ttf');
}
@font-face {
  font-family: SamsungOneLatin-1000;
  src: url('/assets/fonts/samsung-one-latin/samsung-one-latin-1000.ttf');
}

@font-face {
  font-family: SamsungOneLatin1000C;
  src: url('/assets/fonts/samsung-one-latin/samsung-one-latin-1000-c.ttf');
}
@font-face {
  font-family: SamsungOneLatin-1000C;
  src: url('/assets/fonts/samsung-one-latin/samsung-one-latin-1000-c.ttf');
}

@font-face {
  font-family: SamsungOneLatin1100;
  src: url('/assets/fonts/samsung-one-latin/samsung-one-latin-1100.ttf');
}
@font-face {
  font-family: SamsungOneLatin-1100;
  src: url('/assets/fonts/samsung-one-latin/samsung-one-latin-1100.ttf');
}

@font-face {
  font-family: SamsungOneLatin1100C;
  src: url('/assets/fonts/samsung-one-latin/samsung-one-latin-1100-c.ttf');
}
@font-face {
  font-family: SamsungOneLatin-1100C;
  src: url('/assets/fonts/samsung-one-latin/samsung-one-latin-1100-c.ttf');
}
